
import Vue from 'vue';
import { mapGetters } from 'vuex';

import { allowedEarnPlacements, supportedDurations } from 'Store/v2/Earn';
import Button from 'Control/Button.vue';
import Shift from 'UI/Shift.vue';

interface Data {
    activeDurationValue: number;
    supportedDurations: number[];
}

interface Methods {
    setActiveDurationValue: (data: number) => void;
    openSubscriptionPage: () => void;
}

interface Computed {
    duration: Map<number, { apr: number, minAmount: number; }>;
    currentApr: number;
    isPlacementAvailable: boolean;
    isDemoAccount: boolean;
    activeAccountId: string;
}

export default Vue.extend<Data, Methods, Computed, any>({
    components: {
        Button,
        Shift,
    },
    props: {
        product: {
            type: undefined,
            required: true,
        },
    },
    data() {
        return {
            activeDurationValue: 30,
            supportedDurations,
        };
    },
    computed: {
        ...mapGetters({
            activeAccountId: 'Accounts/activeAccountID',
        }),
        duration() {
            return this.product.duration;
        },
        currentApr() {
            return this.duration.get(this.activeDurationValue)?.apr ?? 0;
        },
        isPlacementAvailable() {
            const status = this.$store.state.Placements.maintenanceStatuses.get(
                allowedEarnPlacements[this.$store.state.Earn.selectedPlacementIndex].alias,
            );
            const isLinked = this.$store.getters['Accounts/isPlacementLinkedToActiveAccount'](
                allowedEarnPlacements[this.$store.state.Earn.selectedPlacementIndex].alias,
            );
            return isLinked && status;
        },
        isDemoAccount() {
            return this.activeAccountId === 'DEMOACC';
        },
    },
    methods: {
        setActiveDurationValue(value) {
            if (!this.duration.has(value)) {
                return;
            }
            this.activeDurationValue = value;
        },
        openSubscriptionPage() {
            this.$router.push(
                {
                    path: '/subscription',
                    query: {
                        productAsset: this.product.asset,
                        selectedDuration: String(this.activeDurationValue),
                    },
                },
            );
        },
    },
    mounted() {
        for (let i = 0; i < this.supportedDurations.length; i += 1) {
            if (this.duration.has(supportedDurations[i])) {
                this.activeDurationValue = supportedDurations[i];
                break;
            }
        }
    },
});
