
import Vue from 'vue';

import {
    getPositions,
    SET_POSITIONS_BLOCK_HEIGHT,
    SET_POSITIONS_SORTING_TYPE,
    TOGGLE_POSITIONS_VISIBLE,
} from 'Store/v2/Earn';
import Icon from 'UI/Icon.vue';

interface Data {
    sortType: { name: 'placement' | 'asset' | 'duration' | 'apr'; type: 'asc' | 'desc'; };
}

interface Methods {
    togglePositionsBlockVisible: () => void;
    setSortingType: (data: 'placement' | 'asset' | 'duration' | 'apr') => void;
}

interface Computed {
    isPositionsBlockVisible: boolean;
}

export default Vue.extend<Data, Methods, Computed>({
    components: {
        Icon,
    },
    data() {
        return {
            sortType: {
                name: 'asset',
                type: 'asc',
            },
        };
    },
    computed: {
        isPositionsBlockVisible() {
            return this.$store.state.Earn.isPositionsBlockVisible;
        },
    },
    methods: {
        togglePositionsBlockVisible() {
            this.$store.commit(TOGGLE_POSITIONS_VISIBLE(undefined));
        },
        async setSortingType(sortingTypeName) {
            if (this.sortType.name === sortingTypeName) {
                if (this.sortType.type === 'asc') {
                    this.sortType.type = 'desc';
                } else {
                    this.sortType.type = 'asc';
                }
            } else {
                this.sortType.name = sortingTypeName;
                this.sortType.type = 'asc';
            }

            this.$store.commit(SET_POSITIONS_SORTING_TYPE(this.sortType));
            await this.$store.dispatch(getPositions(undefined));
        },
    },
    watch: {
        isPositionsBlockVisible(value) {
            if (value) {
                this.$store.commit(SET_POSITIONS_BLOCK_HEIGHT(5.9));
            } else {
                this.$store.commit(SET_POSITIONS_BLOCK_HEIGHT(1));
            }
        },
    },
});
