
import Vue from 'vue';

import {
    getProducts,
    ISortingType,
    ITimeFilter,
    SET_PRODUCTS_BLOCK_HEIGHT,
    SET_SORTING_TYPE,
    SET_TIME_FILTERS,
    SORT_PRODUCTS,
    TOGGLE_PRODUCTS_VISIBLE,
} from 'Store/v2/Earn';
import Icon from 'UI/Icon.vue';
import Checkbox from 'Control/Checkbox.vue';

interface Data {
    timeFilters: ITimeFilter[];
    sortType: ISortingType;
}

interface Methods {
    getCheckboxStateByName: (data: string) => boolean;
    toggleCheckbox: (data: string) => void;
    setSortingType: (data: 'placement' | 'asset' | 'duration' | 'apr') => void;
    toggleProductsVisible: () => void;
}

interface Computed {
    isProductsBlockVisible: boolean;
}

export default Vue.extend<Data, Methods, Computed>({
    components: {
        Icon,
        Checkbox,
    },
    data() {
        return {
            sortType: {
                name: 'asset',
                type: 'asc',
            },
            timeFilters: [
                {
                    name: 'All',
                    checked: true,
                },
                {
                    name: '30',
                    checked: true,
                },
                {
                    name: '60',
                    checked: true,
                },
                {
                    name: '90',
                    checked: true,
                },
                {
                    name: '120',
                    checked: true,
                },
            ],
        };
    },
    computed: {
        isProductsBlockVisible() {
            return this.$store.state.Earn.isProductsBlockVisible;
        },
    },
    methods: {
        getCheckboxStateByName(checkboxName) {
            return this.timeFilters.find(({ name }) => name === checkboxName)?.checked ?? false;
        },
        async toggleCheckbox(checkboxName) {
            const index = this.timeFilters.findIndex(({ name }) => name === checkboxName);
            if (index !== -1) {
                this.timeFilters[index].checked = !this.timeFilters[index].checked;
                if (checkboxName === 'All') {
                    if (this.timeFilters[index].checked) {
                        this.timeFilters.forEach((el) => {
                            if (el.name !== 'All') {
                                el.checked = true;
                            }
                        });
                    } else {
                        this.timeFilters.forEach((el) => {
                            if (el.name !== 'All') {
                                el.checked = false;
                            }
                        });
                    }

                    this.$store.commit(SET_TIME_FILTERS(this.timeFilters));
                    await this.$store.dispatch(getProducts(undefined));
                    return;
                }

                this.timeFilters[0].checked = !this.timeFilters.some(({ name, checked }) => name !== 'All' && !checked);

                this.$store.commit(SET_TIME_FILTERS(this.timeFilters));
                await this.$store.dispatch(getProducts(undefined));
            }
        },
        setSortingType(sortingTypeName) {
            if (this.sortType.name === sortingTypeName) {
                if (this.sortType.type === 'asc') {
                    this.sortType.type = 'desc';
                } else {
                    this.sortType.type = 'asc';
                }
            } else {
                this.sortType.name = sortingTypeName;
                this.sortType.type = 'asc';
            }

            this.$store.commit(SET_SORTING_TYPE(this.sortType));
            this.$store.commit(SORT_PRODUCTS(undefined));
        },
        toggleProductsVisible() {
            this.$store.commit(TOGGLE_PRODUCTS_VISIBLE(undefined));
        },
    },
    async mounted() {
        this.$store.commit(SET_TIME_FILTERS(this.timeFilters));
        this.$store.commit(SET_SORTING_TYPE(this.sortType));

        await this.$store.dispatch(getProducts(undefined));
    },
    watch: {
        isProductsBlockVisible(value) {
            if (value) {
                this.$store.commit(SET_PRODUCTS_BLOCK_HEIGHT(9.3));
            } else {
                this.$store.commit(SET_PRODUCTS_BLOCK_HEIGHT(1));
            }
        },
    },
});
