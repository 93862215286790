
import Vue from 'vue';
import { mapGetters } from 'vuex';

import { allowedEarnPlacements, SET_GLOBAL_SEARCH_VALUE, SET_PLACEMENT_INDEX } from 'Store/v2/Earn';
import Icon from 'UI/Icon.vue';
import Search from 'Control/Search.vue';

interface Data {
    allowedEarnPlacements: any;
    selectedPlacementIndex: number;
}

interface Methods {
    setSelectedPlacementIndex: (data: number) => void;
}

interface Computed {
    isThemeDark: boolean;
}

export default Vue.extend<Data, Methods, Computed>({
    components: {
        Icon,
        Search,
    },
    data() {
        return {
            allowedEarnPlacements,
            selectedPlacementIndex: 0,
            globalSearchValue: '',
        };
    },
    computed: {
        ...mapGetters({
            isThemeDark: 'isThemeDark',
        }),
    },
    methods: {
        setSelectedPlacementIndex(index) {
            if (!this.allowedEarnPlacements[index].allowed) {
                return;
            }
            this.selectedPlacementIndex = index;
            this.$store.commit(SET_PLACEMENT_INDEX(index));
        },
    },
    watch: {
        globalSearchValue(value) {
            this.$store.commit(SET_GLOBAL_SEARCH_VALUE(value));
        },
    },
});
