
import Vue from 'vue';
import { mapGetters } from 'vuex';

import PageLayout from 'Common/PageLayout.vue';
import { gridPages } from 'Models/interface';
import layoutNameResolver from 'Mixins/layoutNameResolver';
import EarnHeader from 'Components/Earn/components/EarnHeader.vue';
import EarnPositions from 'Components/Earn/components/EarnPositions.vue';
import EarnProducts from 'Components/Earn/components/EarnProducts.vue';
import { Account } from 'Models/accounts';
import ModuleBlocker from 'UI/ModuleBlocker.vue';

interface Data {
    gridLayout: any;
    currentBreakpoint: string;
}

interface Methods {
    breakpointChangedEvent: (data: string) => void;
}

interface Computed {
    vueGridLayoutBreakpoints: any;
    vueGridLayoutCols: any;
    layout: any;
    positionsBlockHeight: number;
    productsBlockHeight: number;
    isPageAllowed: boolean;
    activeAccount: Account;
}

export default Vue.extend<Data, Methods, Computed>({
    mixins: [
        layoutNameResolver,
    ],
    components: {
        PageLayout,
        EarnHeader,
        EarnPositions,
        EarnProducts,
        ModuleBlocker,
    },
    data() {
        return {
            gridLayout: null,
            currentBreakpoint: 'lg',
        };
    },
    computed: {
        ...mapGetters({
            vueGridLayoutBreakpoints: 'vueGridLayoutBreakpoints',
            vueGridLayoutCols: 'vueGridLayoutCols',
            activeAccount: 'Accounts/activeAccount',
        }),
        layout: {
            get() {
                return this.$store.getters.pageGrid(gridPages.EARN, this.currentBreakpoint);
            },
            set(grid) {
                this.$store.dispatch('changePageGrid', {
                    page: gridPages.EARN,
                    grid,
                    breakpoint: this.currentBreakpoint,
                });
            },
        },
        positionsBlockHeight() {
            return this.$store.state.Earn.ui.positionsBlockHeight;
        },
        productsBlockHeight() {
            return this.$store.state.Earn.ui.productsBlockHeight;
        },
        isPageAllowed() {
            return this.activeAccount?.policies.includes('earn') ?? false;
        },
    },
    methods: {
        breakpointChangedEvent(breakpoint) {
            this.currentBreakpoint = breakpoint;
        },
    },
    watch: {
        productsBlockHeight(value) {
            this.layout = this.$store.getters.pageGrid(gridPages.EARN, this.currentBreakpoint).map((g) => {
                if (g.name === 'EarnProducts') {
                    return {
                        ...g,
                        h: value,
                        y: 2 + this.positionsBlockHeight,
                    };
                }
                return g;
            });
        },
        positionsBlockHeight(value) {
            this.layout = this.$store.getters.pageGrid(gridPages.EARN, this.currentBreakpoint).map((g) => {
                if (g.name === 'EarnPositions') {
                    return {
                        ...g,
                        h: value,
                    };
                }
                if (g.name === 'EarnProducts') {
                    return {
                        ...g,
                        y: 2 + value,
                    };
                }
                return g;
            });
        },
    },
});
