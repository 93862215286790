
import Vue from 'vue';

import Button from 'Control/Button.vue';
import AssetValue from 'UI/AssetValue.vue';
import Shift from 'UI/Shift.vue';
import StakingPosition from 'Entities/privatePresenter/StakingPosition';

interface Methods {
    dateMaker: (data: string) => string;
    openClosePositionPage: () => void;
}

interface Computed {
    endDate: string;
}

interface Props {
    position: StakingPosition;
}

export default Vue.extend<any, Methods, Computed, Props>({
    components: {
        Shift,
        Button,
        AssetValue,
    },
    props: {
        position: {
            type: undefined,
            required: true,
        },
    },
    computed: {
        endDate() {
            return this.dateMaker(this.position.deliverDate);
        },
    },
    methods: {
        dateMaker(dateStr) {
            const date = new Date(dateStr);
            if (!date) {
                return '';
            }
            return Intl.DateTimeFormat('en-EN', {
                day: '2-digit',
                month: 'short',
                year: 'numeric',
                hour12: false,
                hour: 'numeric',
                minute: 'numeric',
            }).format(date);
        },
        openClosePositionPage() {
            this.$router.push({ path: '/close-position', query: { position: JSON.stringify(this.position) } });
        },
    },
});
