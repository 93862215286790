
import Vue from 'vue';

import EarnProductsHeader from 'Components/Earn/components/EarnProductsHeader.vue';
import Product from 'Components/Earn/components/Product.vue';
import { IAggregatedProduct } from 'Store/v2/Earn';

interface Data {
    isSearchActive: boolean;
}

interface Computed {
    products: IAggregatedProduct[];
    isProductsBlockVisible: boolean;
    globalSearchValue: string;
    filteredProducts: IAggregatedProduct[];
    showedProducts: IAggregatedProduct[];
}

export default Vue.extend<Data, any, Computed>({
    components: {
        EarnProductsHeader,
        Product,
    },
    data() {
        return {
            isSearchActive: false,
        };
    },
    computed: {
        products() {
            return this.$store.state.Earn.products;
        },
        isProductsBlockVisible() {
            return this.$store.state.Earn.isProductsBlockVisible;
        },
        globalSearchValue() {
            return this.$store.state.Earn.globalSearchValue;
        },
        filteredProducts() {
            return this.products.filter(({ asset }) => asset.toLowerCase().indexOf(this.globalSearchValue.toLowerCase()) !== -1);
        },
        showedProducts() {
            return this.isSearchActive ? this.filteredProducts : this.products;
        },
    },
    watch: {
        globalSearchValue(value) {
            this.isSearchActive = value.length > 0;
        },
    },
});
