
import Vue from 'vue';
import { mapGetters } from 'vuex';

import Position from 'Components/Earn/components/Position.vue';
import EarnPositionsHeader from 'Components/Earn/components/EarnPositionsHeader.vue';
import { getPositions, SET_POSITIONS_BLOCK_HEIGHT } from 'Store/v2/Earn';
import StakingPosition from 'Entities/privatePresenter/StakingPosition';
import AccountPortfolio from 'Modules/AccountPortfolio/AccountPortfolio.vue';

interface Data {
    isSearchActive: boolean;
}

interface Computed {
    activeAccountId: string;
    quotationAssetSymbol: string;
    positions: StakingPosition[];
    positionsRewards: { rewardAmount: number; rewardAsset: string; }[];
    showBlock: boolean;
    globalSearchValue: string;
    filteredPositions: StakingPosition[];
    showedPositions: StakingPosition[];
    showedPositionsLength: number;
    positionsBlockHeight: number;
}

export default Vue.extend<Data, any, Computed>({
    components: {
        AccountPortfolio,
        Position,
        EarnPositionsHeader,
    },
    data() {
        return {
            isSearchActive: false,
        };
    },
    computed: {
        ...mapGetters({
            activeAccountId: 'Accounts/activeAccountID',
            quotationAssetSymbol: 'Assets/GET_QUOTATION_ASSET_SYMBOL',
        }),
        positions() {
            return this.$store.state.Earn.positions;
        },
        positionsRewards() {
            return this.positions.map(({ rewardAmount, rewardAsset }) => ({ rewardAmount, rewardAsset }));
        },
        showBlock() {
            return this.$store.state.Earn.isPositionsBlockVisible;
        },
        globalSearchValue() {
            return this.$store.state.Earn.globalSearchValue;
        },
        filteredPositions() {
            return this.positions.filter(({ asset }) => asset.toLowerCase().indexOf(this.globalSearchValue.toLowerCase()) !== -1);
        },
        showedPositions() {
            return this.isSearchActive ? this.filteredPositions : this.positions;
        },
        showedPositionsLength() {
            return this.showedPositions.length;
        },
        positionsBlockHeight() {
            return this.$store.state.Earn.ui.positionsBlockHeight;
        },
    },
    async mounted() {
        await this.$store.dispatch(getPositions(undefined));
        if (this.showedPositionsLength > 5) {
            this.$store.commit(SET_POSITIONS_BLOCK_HEIGHT(5.9));
        } else {
            this.$store.commit(SET_POSITIONS_BLOCK_HEIGHT(3.3));
        }
    },
    watch: {
        async activeAccountId(value) {
            if (value) {
                await this.$store.dispatch(getPositions(undefined));
            }
        },
        globalSearchValue(value) {
            this.isSearchActive = value.length > 0;
        },
        showedPositionsLength(value) {
            if (value > 5) {
                this.$store.commit(SET_POSITIONS_BLOCK_HEIGHT(5.9));
            } else {
                this.$store.commit(SET_POSITIONS_BLOCK_HEIGHT(3.3));
            }
        },
        positionsBlockHeight(value) {
            if (value === 1) {
                return;
            }
            if (this.showedPositionsLength > 5) {
                this.$store.commit(SET_POSITIONS_BLOCK_HEIGHT(5.9));
            } else {
                this.$store.commit(SET_POSITIONS_BLOCK_HEIGHT(3.3));
            }
        },
    },
});
